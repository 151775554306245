import React, { FunctionComponent } from 'react';
import NavBar from '../components/navBar';
import Footer from '../components/footer';
import styled from '@emotion/styled';
import { useTheme, Theme } from "@material-ui/core";
import "typeface-asap-condensed";
import "typeface-open-sans";
import "./icons";
import { ThemeWithAccent } from './theme';
import { useStaticQuery, graphql } from 'gatsby';

interface LayoutProps {
    transparentNav?: boolean;
}

const HundyP = styled.main((props: { theme: Theme }) => `
  min-height: calc(100vh - 323px);

  ${props.theme.breakpoints.down("xs")} {
      min-height: calc(100vh - 648px);
  }
`);

const ToolbarCorrect = styled.div`
    min-height: 64px;
`;

const MainLayout: FunctionComponent<LayoutProps> = (props) => {
    const theme = useTheme<ThemeWithAccent>();

    const data = useStaticQuery(graphql`
        query {
            footerJson: generalJson ( block: {eq: "footer" } ) {
                tagline
                addressLine1
                addressLine2
                city
                state
                zip
                copyright
                email
                text
                logo {
                    childImageSharp {
                        fixed(height: 150, width:275, cropFocus: CENTER) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                policies {
                    label
                    link
                }
            }
            allMenuJson {
                edges {
                node {
                    label
                    link
                }
                }
            }
            social: generalJson (block: {eq: "social"}) {
                facebook
                twitter
                instagram
                linkedin
                youtube
            }
        }
    `);

    return (
        <>
            <NavBar allowTransparent={props.transparentNav} color={theme.palette.primary.contrastText} backgroundColor={theme.palette.primary.main} />
            <HundyP theme={theme}>
                <ToolbarCorrect />
                {props.children}
            </HundyP>
            <Footer variant="Text-Links-Contact" {...data.footerJson} {...data.social}  color={theme.palette.primary.contrastText} bgColor={theme.palette.primary.main}
                siteLinks={data.allMenuJson.edges.map(x => x.node)}
             />
        </>
    )
};

export default MainLayout;