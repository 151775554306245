import React, { FunctionComponent } from "react";
import { Box } from "@material-ui/core";

export interface CustomdBoxProps {
    halfRounded?: boolean;
    halfRoundedAlt?: boolean;
    tightBottom?: boolean;
    tightTop?: boolean;
    noBottom?: boolean;
}

export type ExtendedBoxProps = CustomdBoxProps & import('@material-ui/core/Box').BoxProps

const HalfRounded = "0px 64px 0px 64px";
const HalfRoundedAlt = "64px 0px 64px 0px"

const Section:FunctionComponent<ExtendedBoxProps> = (props) => {

    const {halfRounded, halfRoundedAlt, tightBottom, tightTop, noBottom, ...rest} = props;

    const paddingTop = tightTop ? 4 : 8;
    const paddingBottom = noBottom ? 0 : tightBottom ? 4 : 8;

    
    if(halfRounded) {
        return <Box {...rest} pt={{xs: paddingTop / 2, sm: paddingTop}} pb={{xs: paddingBottom / 2, sm: paddingBottom}} borderRadius={HalfRounded}>{props.children}</Box>;
    }
    
    if(halfRoundedAlt) {
        return <Box {...rest} pt={{xs: paddingTop / 2, sm: paddingTop}} pb={{xs: paddingBottom / 2, sm: paddingBottom}} borderRadius={HalfRoundedAlt}>{props.children}</Box>;
    }

    return <Box {...rest} pt={{xs: paddingTop / 2, sm: paddingTop}} pb={{xs: paddingBottom / 2, sm: paddingBottom}} >{props.children}</Box>;
}


export default Section;